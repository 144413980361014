//index.js
import Vue from "vue";
import Vuex from "vuex";

import box from './modules/box.js'
import otherResources from './modules/otherResources.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: {}
  },

  getters: {
    errors(state) {
      return state.errors;
    }
  },

  mutations: {
    SET_ERRORS(state, data) {
      state.errors = data;
    }
  },

  actions: {
    setErrors({ commit }, errors) {
      commit("SET_ERRORS", errors);
    }
  },
    modules: {
      box,
      otherResources
  }
});
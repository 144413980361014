<template>
    <b-spinner 
        v-if="loading" 
        variant="primary" 
        class="spinner" 
    />
    <TableList 
      v-else-if="customer_list !== null"
      :data="customer_list"
      :fields="fields"
      :filter_config="filter_config"
      :loading="loading"
      @rowClick="id => $emit('rowClick', id)"
    >
    </TableList>
    <b-alert 
      v-else
      show
      variant="info"
    >
      No Customer Info Available
    </b-alert>
</template>

<script>
import { mapState } from 'vuex'
import TableList from '../common/TableList.vue'

export default {
  name: 'BoxCustomerList',
  components: {
    TableList
  },
  data() {
      return {
          fields: [
            { key: 'name', label: 'Customer Name', sortable: true },
            {
              key: 'is_active', label: 'Is Active', sortable: true,
              class: "column10"
            },
            { key: 'cust_guid', label: 'Customer GUID', sortable: true},
            {
              key: 'size_on_disk', label: 'Size On Disk (MB)', sortable: true,
              formatter: this.SizeOnDiskFormatter
            },
            {
              key: 'mysql_size', label: 'MySQL Size (MB)', sortable: true,
              formatter: this.MySQLSizeFormatter
            },
            { key: 'gateway', label: 'Uses Gateway', sortable: true }
          ],
          filter_config: {
            string_filter: 'name',
            multiselect: ['is_active']
          }
      }
  },
  async created () {
    await this.load()
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      customer_list: state => state.box.customer_list,
      loading: state => state.box.loading
    })
  },
  methods: {
    async load () {
      await this.$store.dispatch('box/retrieveBoxCustomerList')
    },
    MySQLSizeFormatter (value) {
      return (Math.round(value * 100) / 100).toLocaleString('en-US')
    },
    SizeOnDiskFormatter (value) {
      return (Math.round((value / 1024) * 100) / 100).toLocaleString('en-US')
    }
  }
}
</script>
<style scoped>
.clickable {
  cursor: pointer;
}

.spinner {
  height: 10vw;
  width: 10vw;
}
</style>
<template>
  <div>
    <ResourceNavbar
      :selected="selected"
      @navSelect="id => handleNavSelect(id)"
    />
    <div v-if="selected === 'Connect'">
      <BoxInfo
        v-if="selected_box"
        :id="selected_box"
        @back="handleBack()"
      />
      <BoxList
        v-else
        @rowClick="id => handleRowClick(id)"
      />
    </div>
    <ResourceList v-else-if="selected === 'SFTP'"/>
  </div>
</template>

<script>

import BoxList from './BoxList.vue'
import BoxInfo from './BoxInfo/BoxInfo.vue'
import ResourceList from './ResourceList.vue'
import ResourceNavbar from './NavBar.vue'

export default {
  name: 'PageLayout',
  components: {
    ResourceNavbar,
    BoxList,
    BoxInfo,
    ResourceList
  },
  data() {
      return {
        selected_box: null,
        selected: 'Connect'
      }
  },
  props: {
  },
  computed: {
  },
  methods: {
    handleRowClick(id) {
      this.selected_box = id
    },
    handleBack() {
      this.selected_box = null
    },
    handleNavSelect(id) {
      this.selected = id
    }
  }
}
</script>
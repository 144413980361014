<template>
    <div>
        <b-spinner 
            v-if="loading" 
            variant="primary" 
            class="spinner" 
        />
       <div
            v-else
            class="container"
        >
            <div class="row">
                <b-list-group 
                v-for="(info, key) in box_info"
                :key="`box_info_group_${key}`"
                class="col-12 col-md-6 col-lg-4 mb-4"
                >
                <b-list-group-item variant="secondary">{{ info.name }}</b-list-group-item>
                <b-list-group-item
                    v-for="(item, key) in info.items"
                    :key="`info_item_${key}`"
                    class="d-flex justify-content-between"
                >
                    <div>{{ item.display }}:</div>
                    <div class="d-inline-block text-truncate mw-100">{{ item.value }}</div>
                </b-list-group-item>
                </b-list-group>
            </div>
        </div> 
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BoxInfoGeneral',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  async created () {
    await this.load()
  },
  computed: {
    ...mapState({
      box: state => state.box.box,
      loading: state => state.box.loading
    }),
    box_info () {
      return [
        {
          name: 'General Info',
          items: [
            {
              display: 'DB Info',
              value: this.box.db_info
            },
            {
              display: 'State',
              value: this.box.state
            },
            {
              display: 'Creation Date',
              value: this.box.creation_date
            },
            {
              display: 'Site URL',
              value: this.box.site_url
            },
            {
              display: 'Public IP',
              value: this.box.public_ip
            },
            {
              display: 'Private IP',
              value: this.box.private_ip
            }
          ]
        },
        {
          name: 'Release Data',
          items: [
            {
              display: 'ADO Environment',
              value: this.box.release_data.ADO_environment
            },
            {
              display: 'MTB Core Version',
              value: this.box.release_data.MTBCore_version
            },
            {
              display: 'Code Branch',
              value: this.box.release_data.code_branch
            },
            {
              display: 'Release Tag',
              value: this.box.release_data.release_tag
            }
          ]
        },
        {
          name: 'LP Data',
          items: [
            {
              display: 'LP Region ID',
              value: this.box.lp_data.lp_region_id
            },
            {
              display: 'LP Cert Name',
              value: this.box.lp_data.lp_certname
            },
            {
              display: 'LP Integration',
              value: this.box.lp_data.lp_integration
            },
          ]
        },
        // {
        //   name: 'Admin Data',
        //   items: [
        //     {
        //       display: 'Ansible Host Name',
        //       value: this.box.admin_data.ansible_host_name
        //     }
        //   ]
        // },
        {
          name: 'VM Data',
          items: [
            {
              display: 'Data Disk Type',
              value: this.box.vm_data.data_disk_type
            },
            {
              display: 'Data Disk Size',
              value: this.box.vm_data.data_disk_size
            },
            {
              display: 'OS Version',
              value: `Debian ${this.box.vm_data.os_version}`
            },
            {
              display: 'Machine SKU',
              value: this.box.vm_data.instance_sku
            },
            {
              display: 'VM Name',
              value: this.box.vm_data.vm_name
            }
          ]
        },
        {
          name: 'Azure Data',
          items: [
            {
              display: 'Resource Group Name',
              value: this.box.azure_data.resource_group_name
            },
            {
              display: 'Subscription ',
              value: this.box.azure_data.azure_subscription
            },
            {
              display: 'Region',
              value: this.box.azure_data.azure_region
            }
          ]
        },
        {
          name: 'Customer Data',
          items: [
            {
              display: 'Contract Tier',
              value: this.box.customer_data.contract_tier
            },
            {
              display: 'System Contact',
              value: this.box.customer_data.instance_contact
            }
          ]
        },
        {
          name: 'Backup Info',
          items: [
            {
              display: 'Protocol',
              value: this.box.backup.protocol
            },
            {
              display: 'Region',
              value: this.box.backup.host
            },
            {
              display: 'Remote Directory',
              value: this.box.backup.remote_dir
            }
          ]
        }
      ]
    }
  },
  methods: {
    async load () {
      await this.$store.dispatch('box/retrieveBox')
    }
  }
}
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.spinner {
  height: 10vw;
  width: 10vw;
}
</style>
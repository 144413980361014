<template>
  <div>
    <h1 class="mb-5">{{ msg }}</h1>
    <TableList 
      :data="boxes"
      :fields="fields"
      :filter_config="filter_config"
      :loading="loading"
      :selectable_field="selectable_field"
      @rowClick="id => $emit('rowClick', id)"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import TableList from './common/TableList.vue'

export default {
  name: 'BoxList',
  components: {
    TableList
  },
  data() {
      return {
          fields: [
            { key: 'id', label: 'Box ID', sortable: true },
            { key: 'tags', label: 'Release Tags'},
            { key: 'code_branch', label: 'Code Branch', sortable: true }
          ],
          filter_config: {
            string_filter: 'id',
            multiselect: ['code_branch','tags']
          },
          msg: 'Connect Boxes',
          selectable_field: 'id'
      }
  },
  props: {
  },
  async created () {
    await this.load()
  },
  computed: {
    ...mapState({
      boxes: state => state.box.box_list,
      loading: state => state.box.loading
    })
  },
  methods: {
    async load () {
      await this.$store.dispatch('box/listBoxes')
    }
  }
}
</script>
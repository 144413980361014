<template>
    <b-dropdown 
        :text="display_name" 
        :variant="variant"
    >
        <div
            v-for="option in options"
            :key="`multiselect_option${option.value}`"
        >
            <b-dropdown-item @click="$emit('update', option.value)">
                <b-icon-check
                    v-if="selected_options.includes(option.value)"
                ></b-icon-check>
                {{ option.display }}
            </b-dropdown-item>
        </div>
    </b-dropdown>
</template>

<script>

import { BIconCheck } from 'bootstrap-vue'

export default {
  name: 'MultiSelect',
  components: {
      BIconCheck
  },
  props: {
    options: {
        type: Array,
        default: () => []
    },
    variant: {
        type: String,
        default: 'outline-secondary'
    },
    display_name: {
        type: String,
        default: 'Dropdown'
    },
    selected_options: {
        type: Array,
        default: () => []
    }
  }
}
</script>
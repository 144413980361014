<template>
    <b-nav tabs align="center" justified>
        <b-nav-item
            v-for="(item, key) in nav_items" 
            :key="`nav_${key}`"
            :active="selected === item.id"
            @click="$emit('navSelect', item.id)"
        >
            {{ item.display }}
        </b-nav-item>
    </b-nav>
</template>

<script>


export default {
  name: 'ResourceNavbar',
  data() {
      return {
        nav_items: [
            {id:'Connect', display: 'Connect Boxes' },
            {id:'SFTP', display: 'SFTP Boxes' }
        ],
      }
  }, 
  props: {
    selected: {
        type: String,
        default: 'Connect'
    }
  }
}
</script>
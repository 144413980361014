<template>
    <div>
      <div class="mt-5">
        <b-spinner 
          v-if="loading" 
          variant="primary" 
          class="spinner" 
        />
        <b-table 
          v-else
          striped 
          hover
          fixed
          stacked="sm"
          :items="filtered_data" 
          :fields="fields_final"
          :class="(width >= 768) ? 'flat-table' : 'tight-table'"
        >
          <template 
            v-if="row_details"
            #cell(toggle_details)="row"
          >
            <div @click="row.toggleDetails" class="mr-2">
              <b-icon-chevron-down v-if="row.detailsShowing"/>
              <b-icon-chevron-right v-else/>
            </div>
          </template>
          <template 
            #cell()="data"
          >
            <a 
              v-if="selectable_field === data.field.key"
              class="primary clickable d-inline-block text-truncate mw-100" 
              @click="$emit('rowClick', data.value)"
            >
              {{ data.value }}
            </a>
            <div
              v-else
              class="d-inline-block text-truncate mw-100"
              style=""
            >
              {{ Array.isArray(data.value) ? data.value.join(', ') : data.value }}
            </div>
          </template>
          <template #row-details="row">
            <slot name="row-details" :item="row.item"></slot>
          </template>
        </b-table>
      </div>
    </div>
  </template>
  
  <script>
  
  import { BIconChevronDown, BIconChevronRight } from 'bootstrap-vue'
  
  export default {
    name: 'TableList',
    components: {
      BIconChevronDown,
      BIconChevronRight
    },
    props: {
      data: {
        type: Array,
        default: () => []
      },
      fields: {
        type: Array,
        default: () => []
      },
      selectable_field: {
        type: String,
        default: null
      },
      filter_config: {
        type: Object,
        default: () => { }
      },
      loading: {
        type: Boolean,
        default: false
      },
      row_details: {
        type: Boolean,
        default: false
      },
      filterFunction: {
        type: Function,
        default: (string_filter, field) => {
          return field.indexOf(string_filter) >= 0
        }
      }
    },
    data() {
      return {
        filter_data: {
          string_filter: null,
          multiselect: []
        },
        width: window.innerWidth
      }
    },
    async created () {
      await this.load()
      window.addEventListener("resize", this.onResize);
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    },
    computed: {
      string_filter_display() {
        if (this.filter_data.string_filter) {
          return this.getFieldDisplayName(this.filter_data.string_filter.field)
        }
        return null
      },
      filtered_data() {
        if (this.data.length > 0) {
  
          return this.data.reduce((acc,item) => {
            // filter array to items that match search bar
            let match = true
            if (this.filter_data.string_filter
              && this.filter_data.string_filter.filter !== '') {
              match = this.filterFunction(this.filter_data.string_filter.filter,
                item[this.filter_data.string_filter.field])
            }
            if (!match) return acc
  
            // filter array to items that match multiselects
            const failed_filters = this.filter_data.multiselect.filter((multiselect) => {
              return (multiselect.selected.length > 0 &&
                !this.evalMultiSelectItem(
                  item[multiselect.field],
                  multiselect.selected)) 
            })
  
            // if (this.row_details) item._showDetails = item._showDetails || false
  
            if (failed_filters.length === 0) acc.push(item)
            return acc
          },[])
        }
  
        return []
      },
      fields_final () {
        return this.row_details ? [{ key: 'toggle_details', label: '', class: "column10"},...this.fields] : this.fields
      }
    },
    watch: {
      data() {
        this.buildFilterDataOptions()
      }
    },
    methods: {
      load() {
        this.buildFilterData()
      },
      buildFilterData() {
        if (this.filter_config.string_filter) {
          this.filter_data.string_filter = {
            field: this.filter_config.string_filter,
            filter: ''
          }
        }
  
        if (this.filter_config.multiselect
          && this.filter_config.multiselect.length > 0) {
          this.filter_data.multiselect = []
        }
  
        this.filter_config.multiselect.forEach((field) => {
          this.filter_data.multiselect.push({
            field,
            options: [],
            selected: []
          })
        })
  
        this.buildFilterDataOptions()
      },
      buildFilterDataOptions() {
  
        this.filter_data.multiselect = this.filter_data.multiselect.map((multiselect) => {
          multiselect.options = this.data.reduce((acc, data_item) => {
            
            const arr = Array.isArray(data_item[multiselect.field]) 
              ? data_item[multiselect.field] : [data_item[multiselect.field]]
  
            arr.forEach(item => {
              if (!acc.filter(option => option.value === item).length) {
                acc.push({
                  value: item,
                  display: item
                })
              }
            })
  
            return acc
          }, [])
  
          return multiselect
        })
      },
      updateMultiSelect(key, value) {
        let selected = this.filter_data.multiselect[key].selected
        if (selected.includes(value)) {
          selected = selected.filter(item => item !== value)
        } else {
          selected.push(value)
        }
        this.filter_data.multiselect[key].selected = selected
      },
      getFieldDisplayName(field) {
        return this.fields.find(item => item.key === field).label
      },
      evalMultiSelectItem (value, options) {
        if (Array.isArray(value)) {
          return 0 < options.filter( option => value.indexOf(option) !== -1).length
        } else {
          return options.includes(value)
        }
      },
      onResize () {
        this.width = window.innerWidth
      }
    }
  }
  
  
  </script>
  <style scoped>
  .spinner {
    height: 10vw;
    width: 10vw;
  }
  .clickable {
    cursor: pointer;
  }
  </style>
  <style lang="scss">
  
  .large-dropdown {
    .btn {
      width: 200px;
    }
  }
  .flat-table {
    .column10 {
      width: 10%;
    }
    .column20 {
      width: 20%;
    }
    .column30 {
      width: 30%;
    }
    .column40 {
      width: 40%;
    }
    .column50 {
      width: 50%;
    }
  }
  </style>
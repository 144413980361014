import { request } from '../../utils/api.js'

const state = {
    resource_list: [],
    loading: false,
}

const actions = {
    async listBoxes ({ commit, state }) {
        //ignore if cashed
        if (JSON.stringify(state.resource_list) !== '[]') return

        commit('setLoading', { loading: true })

        const boxes = await request({
            verb: 'GET',
            route: '/otherResources'
        })
            .then(res => res.data)
            .catch(err => console.error(err))
    
        commit('setBoxList', { boxes })
        commit('setLoading', { loading: false })
    },
    
}

const mutations = {
    setBoxList (state, { boxes }) {
        state.resource_list = boxes
    },
    setLoading (state, { loading }) {
        state.loading = loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
<template>
    <b-spinner 
        v-if="loading" 
        variant="primary" 
        class="spinner" 
    />
    <TableList 
      v-else
      row_details
      :data="NSGRules"
      :fields="fields"
      :filter_config="filter_config"
      :loading="loading"
      :filterFunction="CIDRFilterFunction"
      @rowClick="id => $emit('rowClick', id)"
    >
      <template #row-details="item">
        <div class="row">
            <div 
            v-for="(info, key) in item.item.sourceAddress"
            :key="`box_info_group_${key}`"
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
            >
              {{ info }}
            </div>
        </div>
      </template>
    </TableList>
</template>

<script>
import { mapState } from 'vuex'
import TableList from '../common/TableList.vue'
import { contains as CIDRContains } from 'cidr-tools'
import { isIP } from 'is-ip'
import * as cidr from 'is-cidr'

export default {
  name: 'BoxNSGRules',
  components: {
    TableList
  },
  data() {
      return {
          fields: [
            { key: 'name', label: 'Name', sortable: true },
            { key: 'priority', label: 'Priority', sortable: true, class: "column10"},
            { key: 'destinationPortRange', label: 'Port', sortable: true, class: "column10" },
            { key: 'sourceAddress', label: 'Address', class: "column50 px-5" }
          ],
          filter_config: {
            string_filter: 'sourceAddress',
            multiselect: ['destinationPortRange']
          }
      }
  },
  async created () {
    await this.load()
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      NSGRules: state => state.box.NSGRules,
      loading: state => state.box.loading
    })
  },
  methods: {
    async load () {
      await this.$store.dispatch('box/retrieveBoxNSGRules')
    },
    CIDRFilterFunction: (string_filter, field) => {
      return field.findIndex(element => {
        if (element.indexOf(string_filter) >= 0) {
          return true
        } else if (isIP(string_filter) && cidr.v4(element)) {
          return CIDRContains(element,string_filter)
        } else return false
      }) >= 0
    }
  }
}
</script>
<style scoped>
.clickable {
  cursor: pointer;
}

.spinner {
  height: 10vw;
  width: 10vw;
}
</style>
<template>
  <div>
      <h1 class="mb-5 title">
        <b-icon-arrow-left 
          class="clickable"
          @click="$emit('back')"
        />
        {{ id }}
      </h1>
      <div class="container">
        <b-nav pills class="mb-5">
          <b-nav-item 
            v-for="(item, key) in pages"
            :key="`info_item_${key}`"
            :active="selected_page === item.name"
            @click="selected_page = item.name"
          >
            {{ item.display }}
          </b-nav-item>
        </b-nav>
        <BoxInfoGeneral
          v-if="selected_page === 'general'"
        />
        <BoxNSGRules
          v-else-if="selected_page === 'nsg'"
        />
        <BoxCustomerList
          v-else-if="selected_page === 'customer'"
        />
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BIconArrowLeft } from 'bootstrap-vue'
import BoxInfoGeneral from './General.vue'
import BoxNSGRules from './NSGRules.vue'
import BoxCustomerList from './CustomerInfo.vue'

export default {
  name: 'BoxInfo',
  components: {
    BIconArrowLeft,
    BoxInfoGeneral,
    BoxNSGRules,
    BoxCustomerList
  },
  data() {
      return {
        pages: [
          {
            name: "general",
            display: "General Info"
          },
          {
            name: "nsg",
            display: "NSG Rules"
          },
          {
            name: "customer",
            display: "Customer List"
          }
        ],
        selected_page: "general"
      }
  },
  created () {
    this.load()
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      box: state => state.box.box
    })
  },
  methods: {
    load () {
      this.$store.commit('box/setBoxID',{ id: this.id })
    }
  }
}
</script>
<style scoped>
.title {
  text-align: left;
}
.clickable {
  cursor: pointer;
}
</style>
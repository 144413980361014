<template>
    <div>
      <h1 class="mb-5">{{ msg }}</h1>
      <TableList 
        :data="boxes"
        :fields="fields"
        :loading="loading"
      />
    </div>
  </template>
  
  <script>
  
  import { mapState } from 'vuex'
  import TableList from './common/ResourceList.vue'
  
  export default {
    name: 'ResourceList',
    components: {
      TableList
    },
    data() {
        return {
            fields: [
              { key: 'id', label: 'Box ID'},
              { key: 'url', label: 'URL'},
            ],
            msg: 'New SFTP Boxes',
        }
    },
    props: {
    },
    async created () {
      await this.load()
    },
    computed: {
      ...mapState({
        boxes: state => state.otherResources.resource_list,
        loading: state => state.box.loading
      })
    },
    methods: {
      async load () {
        await this.$store.dispatch('otherResources/listBoxes')
      }
    }
  }
  </script>
import { request } from '../../utils/api.js'

const state = {
    box_list: [],
    loading: false,
    box: {},
    NSGRules: [],
    box_id: null,
    customer_list: []
}

const actions = {
    async listBoxes ({ commit, state }) {
        //ignore if cashed
        if (JSON.stringify(state.box_list) !== '[]') return

        commit('setLoading', { loading: true })

        const boxes = await request({
            verb: 'GET',
            route: '/api/box'
        })
            .then(res => res.data)
            .catch(err => console.error(err))
    
        commit('setBoxList', { boxes })
        commit('setLoading', { loading: false })
    },
    async retrieveBox ({ commit, state }) {
        //ignore if cashed
        if( JSON.stringify(state.box) !== '{}') return

        commit('setLoading', { loading: true })

        const id = state.box_id

        const box = await request({
            verb: 'GET',
            route: `/api/box/${id}`
        })
            .then(res => res.data)
            .catch(err => console.error(err))

        commit('setBox', { box })
        commit('setLoading', { loading: false })
    },
    async retrieveBoxNSGRules ({ commit, state }) {
        //ignore if cashed
        if( JSON.stringify(state.NSGRules) !== '[]') return

        commit('setLoading', { loading: true })

        const id = state.box_id

        let rules = await request({
            verb: 'GET',
            route: `/api/box/${id}/nsg_rules`
        })
            .then(res => res.data)
            .catch(err => console.error(err))
        
        rules = rules.map(rule => {
            rule.sourceAddress = rule.sourceAddressPrefix ?
                [rule.sourceAddressPrefix] :
                rule.sourceAddressPrefixes
            return rule
        })

        commit('setNSGRules', { rules })
        commit('setLoading', { loading: false })
    },
    async retrieveBoxCustomerList ({ commit, state }) {
        //ignore if cashed
        if( JSON.stringify(state.customer_list) !== '[]') return

        commit('setLoading', { loading: true })

        const id = state.box_id

        let customers = await request({
            verb: 'GET',
            route: `/api/box/${id}/customers`
        })
            .then(res => res.data)
            .catch(err => console.error(err))
        
        if (customers === '') customers = null
        console.log(customers)

        commit('setCustomers', { customers })
        commit('setLoading', { loading: false })
    }
}

const mutations = {
    setBoxList (state, { boxes }) {
        state.box_list = boxes
    },
    setBox (state, { box }) {
        state.box = box
    },
    setNSGRules (state, { rules }) {
        state.NSGRules = rules
    },
    setCustomers (state, { customers }) {
        state.customer_list = customers
    },
    setLoading (state, { loading }) {
        state.loading = loading
    },
    setBoxID (state, { id }) {
        if (state.box_id !== id) {
            state.box = {}
            state.NSGRules = []
            state.box_id = id
            state.customer_list = []
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
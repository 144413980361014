import { render, staticRenderFns } from "./ResourceList.vue?vue&type=template&id=96fb2f1c&scoped=true&"
import script from "./ResourceList.vue?vue&type=script&lang=js&"
export * from "./ResourceList.vue?vue&type=script&lang=js&"
import style0 from "./ResourceList.vue?vue&type=style&index=0&id=96fb2f1c&scoped=true&lang=css&"
import style1 from "./ResourceList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96fb2f1c",
  null
  
)

export default component.exports
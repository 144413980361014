<template>
  <div id="app" class="container-md  mx-auto">
    <PageLayout 
      v-if="user_loaded"
      msg="Connect Boxes"
    />
  </div>
</template>

<script>
import PageLayout from './components/PageLayout.vue'
import { tokenExchange } from '@utils/api.js'

export default {
  name: 'App',
  metaInfo: {
    title: 'Connect Systems'
  },
  components: {
    PageLayout
  },
  async beforeCreate () {
    await tokenExchange()
    this.user_loaded = true
  },
  data () {
    return {
      user_loaded: false
    }
  }
}
</script>

<style>
#app {
  text-align: center;
  margin-top: 60px;
}
</style>
